//import useCurrentUser from '@api/user/auth/queries/useCurrentUser';
//import Button from '@components/button';
import { config } from '@shared/config/config';

//import { useConnectWallet } from '@shared/hooks';

const AvaChat = () => {
  // const { data: user } = useCurrentUser();
  const avaChatUrl = config.avaChatUrl;
  //const { handleConnect, isReconnecting } = useConnectWallet();

  /*if (!user?.data) {
    return (
      <div className="mx-auto flex max-w-[1120px] flex-col gap-10 px-4 py-16 md:p-15 md:px-6">
        <video
          className="w-full object-cover"
          autoPlay
          controls={true}
          loop
          muted
          playsInline
        >
          <source
            src={`${config.s3bucketUrl}/upcoming-apps/4_5778557555336813196.mp4`}
            type="video/mp4"
          />
        </video>
        <div className="roundedBlTr flex flex-wrap items-center justify-center gap-4 bg-neutral800 p-8 md:justify-between">
          <p className="heading-xxsmall text-center">
            Connect your wallet to start chatting with Ava!
          </p>
          <Button
            onClick={handleConnect}
            disabled={isReconnecting}
            className="w-full md:max-w-max"
          >
            Connect wallet
          </Button>
        </div>
      </div>
    );
  }*/

  return (
    <div className="max-ava-chat-md:m-4 max-ava-chat-md:mt-16">
      <iframe
        title="Ava Chat"
        className={'h-[85dvh] w-full md:h-[1000px]'}
        sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts allow-downloads allow-pointer-lock"
        allow="accelerometer; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; clipboard-write; fullscreen;"
        allowFullScreen
        /*src={
          !!user?.data
            ? `${avaChatUrl}?type=basic&userid=${user.data.userId}`
            : ''
        }*/
        src={`${avaChatUrl}?type=basic`}
      ></iframe>
    </div>
  );
};

export default AvaChat;
